<template>
  <b-sidebar
    id="invite-employee-sidebar"
    ref="sidebar"
    class="user-form-sidebar"
    width="50%"
    title="Invite Employees"
    bg-variant="white"
    right
    shadow
    backdrop
    no-header
    @shown="getUserData()"
    @hidden="resetData()"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          class="mb-0"
        >
          Invite Employees
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card
        no-body
        class="container my-1"
      >
        <validation-observer ref="invite-employee">
          <b-form ref="user-invite-form">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                >
                  <b-input-group>
                    <b-form-input
                      id="first_name"
                      v-model="userData.first_name"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                >
                  <b-input-group>
                    <b-form-input
                      id="last_name"
                      v-model="userData.last_name"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group
                    label="Employee Email"
                    label-for="email"
                  >
                    <b-input-group>
                      <b-form-input
                        id="email"
                        v-model="userData.email"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required|phone"
                >
                  <b-form-group
                    label="Employee Phone"
                    label-for="phone"
                  >
                    <b-input-group
                      prepend="+880"
                    >
                      <b-form-input
                        id="phone"
                        v-model="userData.phone"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Designation"
                  label-for="designation"
                >
                  <b-input-group>
                    <b-form-input
                      id="designation"
                      v-model="userData.designation"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Select Department"
                  label-for="department"
                >
                  <v-select
                    id="department"
                    v-model="userData.department"
                    :options="departmentList"
                    :reduce="option => option.id"
                    label="name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="(isUserForm || allFields) && !id"
                md="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|password"
                >
                  <b-form-group
                    label="Password"
                    label-for="user-password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="user-password"
                        v-model="userData.password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="isUserForm || allFields"
                md="6"
              >
                <b-form-group
                  label="Select Role"
                  label-for="user-role"
                >
                  <v-select
                    id="user-role"
                    v-model="userData.user_role"
                    :options="['ADMIN', 'USER']"
                    label="name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <div class="d-flex justify-content-start">
          <b-button
            variant="primary"
            class="mr-2"
            :disabled="isLoading"
            @click="sendInvites()"
          >
            <b-spinner
              v-if="isLoading"
              small
            />
            Done
          </b-button>
          <b-button
            variant="outline-primary"
            @click="hide"
          >
            Close
          </b-button>
        </div>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, password, phone } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import helper from '@/mixins/helper'
import api from '@/libs/api'

export default {
  name: 'UserInvitationForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, helper],
  props: {
    userInfo: {
      type: Object,
    },
    id: {
      type: String,
      default: '',
    },
    isUserForm: {
      type: Boolean,
      default: false,
    },
    allFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      password,
      phone,
      isLoading: false,
      userData: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        designation: null,
        department: null,
      },
      departmentList: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getDepartments()
  },
  methods: {
    sendInvites() {
      this.$refs['invite-employee'].validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = JSON.parse(JSON.stringify(this.userData))
          formData.phone = `+880${formData.phone}`
          formData.email = (formData.email).toLowerCase()
          this.callInvitationApi(formData)
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
    getDepartments() {
      api.getData('/b2e_dashboard/department/', true).then(response => {
        if (response.data.status) {
          this.departmentList = response.data.result.results
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry Something went wrong')
      })
    },
    callInvitationApi(data) {
      let apiRequest = ''
      if (this.id && this.isUserForm) {
        apiRequest = api.updateData(`/b2e_dashboard/update_employee/${this.id}/`, true, data)
      } else if (this.isUserForm || this.allFields) {
        apiRequest = api.postData('b2e_dashboard/add_employee/', true, data)
      } else {
        apiRequest = api.postData('b2e_dashboard/send/invitation/', true, data)
      }
      apiRequest.then(response => {
        if (response.data.status) {
          this.$emit('getUserList')
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Invite Sent Successfully')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry Something went wrong')
      }).finally(() => {
        this.isLoading = false
      })
    },
    getUserData() {
      if (this.userInfo) {
        this.userData = JSON.parse(JSON.stringify(this.userInfo))
        if (this.userData.department) {
          this.userData.department = this.departmentList.filter(department => department.name === this.userData.department.name)[0].id
        }
      } else {
        this.resetData()
      }
    },
    resetData() {
      this.userData = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        designation: null,
        department: null,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
