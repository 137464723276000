<template>
  <div>
    <div class="mb-2">
      <b-row class="text-right">
        <b-col
          cols="12"
          md="12"
        >
          <b-button
            v-if="!isUserPage"
            id="invite-employee"
            variant="primary"
            class="mr-1"
            @click="getSelectedForm(false)"
          >
            Invite Employee
          </b-button>
          <b-button
            id="add-employee"
            variant="primary"
            @click="isUserPage ? getUserData() : getSelectedForm(true)"
          >
            {{ isUserPage ? 'Add User' : 'Add Employee' }}
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-card no-body>
      <div class="m-2">
        <b-row class="justify-content-end">
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Search..."
                @input="debounceSearch(meta.offset=0, getRequestedQuery)"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :items="employeeList"
        :fields="tableColumns"
        :busy="isTableLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            class="action-menu"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="getUserData(data.item)">
              <feather-icon icon="Edit2Icon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal.user-modal
              @click="getUserToBlock(data.item)"
            >
              <feather-icon :icon="data.item.is_blocked === null || data.item.is_blocked ? 'CircleIcon' : 'SlashIcon'" />
              <span class="align-middle ml-50">
                {{ data.item.is_blocked === null || data.item.is_blocked ? 'Activate' : 'Deactivate' }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(name)="data">
          {{ data.item.first_name && data.item.last_name ? `${data.item.first_name} ${data.item.last_name}` : 'N/A' }}
        </template>
        <template #cell(is_blocked)="data">
          <b-badge
            pill
            :variant="data.item.is_blocked === null || data.item.is_blocked ? 'light-danger' : 'light-success'"
          >
            {{ data.item.is_blocked === null || data.item.is_blocked ? 'Inactive' : 'Active' }}
          </b-badge>
        </template>
      </b-table>
      <TablePagination
        :offset="meta.offset"
        :total="meta.total"
        :limit="meta.limit"
        :list-data="employeeList"
        @paginate="paginate"
      />
      <UserInvitationForm
        :id="b2eId"
        :user-info="userData"
        :is-user-form="isUserPage"
        :all-fields="showAllFields"
        @getUserList="getEmployeeList"
      />
      <b-modal
        id="user-modal"
        ref="employee-modal"
        ok-only
        ok-variant="danger"
        modal-class="modal-danger"
        centered
        title="Block User"
      >
        <b-card-text>
          Are you sure you want to {{ userToBlock.is_blocked === null
            || userToBlock.is_blocked ? 'activate' : 'deactivate' }} this user ?
        </b-card-text>
        <template #modal-footer>
          <b-button
            variant="danger"
            :disabled="isActionLoading"
            @click="blockUser()"
          >
            <b-spinner
              v-if="isActionLoading"
              small
            />
            Confirm
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import TablePagination from '@/components/common/TablePagination.vue'
import UserInvitationForm from './UserInvitationForm.vue'

export default {
  name: 'Employees',
  components: {
    TablePagination,
    UserInvitationForm,
  },
  mixins: [helper],
  props: {
    isUserPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTableLoading: false,
      searchQuery: '',
      employeeList: [],
      userData: {},
      userToBlock: {},
      b2eId: '',
      isActionLoading: false,
      showAllFields: false,
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'actions', label: '' },
        { key: 'name', label: 'Name' },
        {
          key: 'email', label: 'Email', thClass: 'md', formatter: value => value || 'N/A',
        },
        {
          key: 'phone', label: 'Phone', thClass: 'md', formatter: value => value || 'N/A',
        },
        {
          key: 'designation', label: 'Designation', thClass: 'sm', formatter: value => value || 'N/A',
        },
        {
          key: 'department.name', label: 'Department', thClass: 'md', formatter: value => value || 'N/A',
        },
        { key: 'is_blocked', label: 'Status' },
      ],
    }
  },
  computed: {
    filterQuery() {
      return this.searchQuery ? `&search=${this.searchQuery}` : ''
    },
  },
  mounted() {
    this.getEmployeeList()
  },
  methods: {
    getEmployeeList() {
      this.isTableLoading = true
      api.getData(`b2e_dashboard/employee_list/?offset=${this.meta.offset}${this.filterQuery}`, true).then(response => {
        if (response.data.status) {
          this.employeeList = response.data.result.results
          this.meta = response.data.result.meta
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isTableLoading = false
      })
    },
    getRequestedQuery() {
      this.isTableLoading = true
      this.meta.offset = 0
      this.getEmployeeList()
    },
    paginate(offset) {
      this.meta.offset = offset
      this.getEmployeeList()
    },
    getSelectedForm(data) {
      this.showAllFields = data
      this.getUserData()
    },
    getUserData(data) {
      if (data) {
        this.userData = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          designation: data.designation,
          department: data.department,
          user_role: data.user_role,
        }
        this.b2eId = data.b2e_id
      } else {
        this.userData = null
        this.b2eId = ''
      }
      this.showUserForm()
    },
    showUserForm() {
      this.$root.$emit('bv::toggle::collapse', 'invite-employee-sidebar')
    },
    getUserToBlock(data) {
      this.userToBlock = data
    },
    blockUser() {
      this.isActionLoading = true
      api.updateData(`/b2e_dashboard/block_employee/${this.userToBlock.b2e_id}/`, true, {
        block: !this.userToBlock.is_blocked,
      }).then(response => {
        if (response.data.status) {
          this.getEmployeeList()
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request has been processed successfully!')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      }).finally(() => {
        this.isActionLoading = false
        this.$refs['employee-modal'].hide()
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
