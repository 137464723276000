var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"sidebar",staticClass:"user-form-sidebar",attrs:{"id":"invite-employee-sidebar","width":"50%","title":"Invite Employees","bg-variant":"white","right":"","shadow":"","backdrop":"","no-header":""},on:{"shown":function($event){return _vm.getUserData()},"hidden":function($event){return _vm.resetData()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Invite Employees ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('b-card',{staticClass:"container my-1",attrs:{"no-body":""}},[_c('validation-observer',{ref:"invite-employee"},[_c('b-form',{ref:"user-invite-form"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"first_name"},model:{value:(_vm.userData.first_name),callback:function ($$v) {_vm.$set(_vm.userData, "first_name", $$v)},expression:"userData.first_name"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"last_name"},model:{value:(_vm.userData.last_name),callback:function ($$v) {_vm.$set(_vm.userData, "last_name", $$v)},expression:"userData.last_name"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Employee Email","label-for":"email"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Employee Phone","label-for":"phone"}},[_c('b-input-group',{attrs:{"prepend":"+880"}},[_c('b-form-input',{attrs:{"id":"phone"},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Designation","label-for":"designation"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"designation"},model:{value:(_vm.userData.designation),callback:function ($$v) {_vm.$set(_vm.userData, "designation", $$v)},expression:"userData.designation"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Select Department","label-for":"department"}},[_c('v-select',{attrs:{"id":"department","options":_vm.departmentList,"reduce":function (option) { return option.id; },"label":"name"},model:{value:(_vm.userData.department),callback:function ($$v) {_vm.$set(_vm.userData, "department", $$v)},expression:"userData.department"}})],1)],1),((_vm.isUserForm || _vm.allFields) && !_vm.id)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Password","label-for":"user-password"}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"user-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e(),(_vm.isUserForm || _vm.allFields)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Select Role","label-for":"user-role"}},[_c('v-select',{attrs:{"id":"user-role","options":['ADMIN', 'USER'],"label":"name"},model:{value:(_vm.userData.user_role),callback:function ($$v) {_vm.$set(_vm.userData, "user_role", $$v)},expression:"userData.user_role"}})],1)],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.sendInvites()}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Done ")],1),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":hide}},[_vm._v(" Close ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }